@import './../styles/common.scss';



@media all and (min-width: 1200px){
	#high-scores-container{
		transform: rotate(-1deg) translateY(-30px) translateX(-34px);
		padding-bottom: 0px;
		margin-bottom: -10px;
		h2{
			transform: rotate(-3deg) translateY(-14px) translateX(-14px);
			z-index: 3;
		}
	}

	#high-scores table tr td:nth-child(1){
		padding-left: 25px;
	}
}

@media all and (max-width: 640px){
	#high-scores{
		table{
			td:nth-child(3), th:nth-child(3){
				display: none;
			}
		}
	}
}



#high-scores{
	background: $dark-gray;
	color: lighten($blueish-gray, 50%);
	@include border-radius($large-radius);
	position: relative;
	overflow: hidden;
	@include box-shadow(1px, 1px, 10px, rgba(33,33,33,.3));
}

#in-game-score-container{
	max-width: 700px;
    margin: 0 auto;
    font-size: 1.4em;
	.footer{
		margin-top: 25px;
		font-size: 20px;
	}
}


table.score-list{
	width: 100%;
	max-width: 100%;
	color: lighten($blueish-gray, 50%);
	font-family: 'distant_galaxyregular';
	letter-spacing: 3px;
	font-size: 0.9em;
	background: none;
	tbody tr:nth-child(even), thead{
		background: none;
	}
	td:nth-child(2), th:nth-child(2){
		text-align: left;
		width: 50%;
	}

	td:nth-child(4), th:nth-child(4){
		text-align: right;
		width: 50%;
	}


	td:nth-child(1), th:nth-child(1), td:nth-child(3), th:nth-child(3){
		display: none;
	}

	thead{
		display: none;
	}

	td, th{
		padding: 10px 40px;
		text-align: center;
	}

	tr{
		border-bottom: 1px solid rgba(255,255,255,0.1);
	}

	tr:nth-child(10), tr:last-child{
		border-bottom: 0px;
	}
}

.in-mobile-web-view{
	table.score-list{
		td, th{
			padding: 8px 16px 5px 16px;
		}

		tr:nth-child(8),tr:nth-child(9),tr:nth-child(10),tr:nth-child(11){
			display: none;
		}

		tr:nth-child(7){
			border-bottom: 0px;
		}
	}
}
