@import './../styles/common.scss';

.crypto-block{
	width: 100%;
	cursor: pointer;
	background: $dark-gray;
	color: white;
	text-decoration: none;
	margin-bottom: 20px;
	padding: 10px 10px;
	@include border-radius($large-radius);
	.top{
	  	padding: 10px;
	  	padding-top: 5px;
		.title{
			float: left;

			img{
				height: 24px;
				width: auto;
				display: inline-block;
				vertical-align: middle;
				margin-right: 4px;
			}

			h3{
				display: inline-block;
				vertical-align: middle;
			}
		}

		.copy-icon{
			float: right;
			max-width: 17px;
			height: auto;
			opacity: .85;
		}
	}


	.address{
		word-wrap: break-word;
		border: 1px dashed $lighter-gray;
		padding: 10px;
		line-height: 1.25;
		color: rgba(255,255,255,.5);
		@include border-radius(2px);
		@include selection{
			color: red;
			bacground: white;
		};
	}
}

.crypto-block:hover{
	background: $lighter-gray;
	.address{
		border-color: $dark-gray;
	}
}

.crypto-block.copied{
	background: darken($dark-gray, 10%);
	@include transition(all ease-out 200ms);
}

.crypto-block:last-child{
	margin-bottom: 0px;
}