@import './../styles/common.scss';

.accordion{
	overflow: hidden;
	background: $dark-gray;
	padding: 12px 0px;
	margin-top: 20px;
	@include border-radius($large-radius);
	.accordion-item{
		h3.title{
			cursor: pointer;
			padding: 12px 20px;
			border-bottom: 1px solid lighten($dark-gray, 1%);
			border-top: 1px solid lighten($dark-gray, 1%);
		}

		ul.content{
			max-height: 0px;
			overflow: hidden;
			color: lighten($blueish-gray, 50%);
			line-height: 1.35;
			marginleft: 20px;
			list-style-type: circle;
			@include transition(all linear 500ms);
			li.content-line{
				padding: 8px 20px;
				line-height: 1.25;
			}

			li.content-line:first-child{
				margin-top: 15px;
			}

			li.content-line:last-child{
				margin-bottom: 15px;
			}
		}
	}

	.accordion-item.active{
		.title{
			background: lighten($dark-gray, 1%);
			border-bottom: 1px solid transparent;
			border-top: 1px solid transparent;
		}
		.content{
			max-height: 800px;
		}
	}
}