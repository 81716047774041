$blueish-gray: #4A5660;
$red: #A22C29;
$dark-gray: rgba(30,30,30, .75);
$lighter-gray: rgba(50,50,50, 1);
$purple: #8E5DC0;
$blue: #3B69C0;
$green: #00C472;
$light: lighten($blueish-gray, 30%);
$pink: #E40066;

$small-radius: 5px;
$large-radius: 12px;
h1{
	font-family: 'distant_galaxyregular';
	letter-spacing: 2px;
	color: lighten($blueish-gray, 30%);
	font-size: 2em;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
	 -moz-border-radius: $radius;
	  -ms-border-radius: $radius;
		  border-radius: $radius;
}

@mixin transition($content) {
	-webkit-transition: $content;
	-moz-transition: $content;
	-ms-transition: $content;
	-o-transition: $content;
	transition: $content;
}

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin noselect(){
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently */
}

@mixin selection {
	::-moz-selection { @content; }
	::selection { @content; }
}

@mixin linear-gradient($angle, $color-stops...) {
	$_angle-with-vendor-prefix: "";
	$_angle: "";
	@if $angle == "to top" or $angle == "bottom" {
		$_angle-with-vendor-prefix: bottom;
		$_angle: to top;
	} @else if $angle == "to right" or $angle == "left" {
		$_angle-with-vendor-prefix: left;
		$_angle: to right;
	} @else if $angle == "to bottom" or $angle == "top" {
		$_angle-with-vendor-prefix: top;
		$_angle: to bottom;
	} @else if $angle == "to left" or $angle == "right" {
		$_angle-with-vendor-prefix: right;
		$_angle: to left;
	} @else if $angle == "to top right" or $angle == "bottom left" {
		$_angle-with-vendor-prefix: bottom left;
		$_angle: to top right;
	} @else if $angle == "to bottom right" or $angle == "top left" {
		$_angle-with-vendor-prefix: top left;
		$_angle: to bottom right;
	} @else if $angle == "to bottom left" or $angle == "top right" {
		$_angle-with-vendor-prefix: top right;
		$_angle: to bottom left;
	} @else if $angle == "to top left" or $angle == "bottom right" {
		$_angle-with-vendor-prefix: bottom right;
		$_angle: to top left;
	} @else {
		$_angle-with-vendor-prefix: $angle % 360;
		$_angle: (90 - $angle) % 360;
	}
	background: -webkit-linear-gradient($_angle-with-vendor-prefix, $color-stops);
	background: -moz-linear-gradient($_angle-with-vendor-prefix, $color-stops);
	background: -o-linear-gradient($_angle-with-vendor-prefix, $color-stops);
	background: linear-gradient($_angle, $color-stops);
}