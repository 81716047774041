h1 {
  letter-spacing: 2px;
  color: #95a3ae;
  font-family: distant_galaxyregular;
  font-size: 2em;
}

.crypto-block {
  cursor: pointer;
  color: #fff;
  -ms-border-radius: 12px;
  background: #1e1e1ebf;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  text-decoration: none;
}

.crypto-block .top {
  padding: 5px 10px 10px;
}

.crypto-block .top .title {
  float: left;
}

.crypto-block .top .title img {
  vertical-align: middle;
  width: auto;
  height: 24px;
  margin-right: 4px;
  display: inline-block;
}

.crypto-block .top .title h3 {
  vertical-align: middle;
  display: inline-block;
}

.crypto-block .top .copy-icon {
  float: right;
  opacity: .85;
  max-width: 17px;
  height: auto;
}

.crypto-block .address {
  word-wrap: break-word;
  color: #ffffff80;
  -ms-border-radius: 2px;
  border: 1px dashed #323232;
  border-radius: 2px;
  padding: 10px;
  line-height: 1.25;
}

.crypto-block .address ::selection {
  color: red;
  bacground: white;
}

.crypto-block:hover {
  background: #323232;
}

.crypto-block:hover .address {
  border-color: #1e1e1ebf;
}

.crypto-block.copied {
  -o-transition: all ease-out .2s;
  background: #050505bf;
  transition: all .2s ease-out;
}

.crypto-block:last-child {
  margin-bottom: 0;
}

h1 {
  letter-spacing: 2px;
  color: #95a3ae;
  font-family: distant_galaxyregular;
  font-size: 2em;
}

.accordion {
  -ms-border-radius: 12px;
  background: #1e1e1ebf;
  border-radius: 12px;
  margin-top: 20px;
  padding: 12px 0;
  overflow: hidden;
}

.accordion .accordion-item h3.title {
  cursor: pointer;
  border-top: 1px solid #212121bf;
  border-bottom: 1px solid #212121bf;
  padding: 12px 20px;
}

.accordion .accordion-item ul.content {
  color: #cfd5da;
  marginleft: 20px;
  -o-transition: all linear .5s;
  max-height: 0;
  line-height: 1.35;
  list-style-type: circle;
  transition: all .5s linear;
  overflow: hidden;
}

.accordion .accordion-item ul.content li.content-line {
  padding: 8px 20px;
  line-height: 1.25;
}

.accordion .accordion-item ul.content li.content-line:first-child {
  margin-top: 15px;
}

.accordion .accordion-item ul.content li.content-line:last-child {
  margin-bottom: 15px;
}

.accordion .accordion-item.active .title {
  background: #212121bf;
  border-top: 1px solid #0000;
  border-bottom: 1px solid #0000;
}

.accordion .accordion-item.active .content {
  max-height: 800px;
}

h1 {
  letter-spacing: 2px;
  color: #95a3ae;
  font-family: distant_galaxyregular;
  font-size: 2em;
}

@media (min-width: 1200px) {
  #high-scores-container {
    margin-bottom: -10px;
    padding-bottom: 0;
    transform: rotate(-1deg)translateY(-30px)translateX(-34px);
  }

  #high-scores-container h2 {
    z-index: 3;
    transform: rotate(-3deg)translateY(-14px)translateX(-14px);
  }

  #high-scores table tr td:first-child {
    padding-left: 25px;
  }
}

@media (max-width: 640px) {
  #high-scores table td:nth-child(3), #high-scores table th:nth-child(3) {
    display: none;
  }
}

#high-scores {
  color: #cfd5da;
  -ms-border-radius: 12px;
  background: #1e1e1ebf;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 10px #2121214d;
}

#in-game-score-container {
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.4em;
}

#in-game-score-container .footer {
  margin-top: 25px;
  font-size: 20px;
}

table.score-list {
  color: #cfd5da;
  letter-spacing: 3px;
  background: none;
  width: 100%;
  max-width: 100%;
  font-family: distant_galaxyregular;
  font-size: .9em;
}

table.score-list tbody tr:nth-child(2n), table.score-list thead {
  background: none;
}

table.score-list td:nth-child(2), table.score-list th:nth-child(2) {
  text-align: left;
  width: 50%;
}

table.score-list td:nth-child(4), table.score-list th:nth-child(4) {
  text-align: right;
  width: 50%;
}

table.score-list td:first-child, table.score-list th:first-child, table.score-list td:nth-child(3), table.score-list th:nth-child(3), table.score-list thead {
  display: none;
}

table.score-list td, table.score-list th {
  text-align: center;
  padding: 10px 40px;
}

table.score-list tr {
  border-bottom: 1px solid #ffffff1a;
}

table.score-list tr:nth-child(10), table.score-list tr:last-child {
  border-bottom: 0;
}

.in-mobile-web-view table.score-list td, .in-mobile-web-view table.score-list th {
  padding: 8px 16px 5px;
}

.in-mobile-web-view table.score-list tr:nth-child(8), .in-mobile-web-view table.score-list tr:nth-child(9), .in-mobile-web-view table.score-list tr:nth-child(10), .in-mobile-web-view table.score-list tr:nth-child(11) {
  display: none;
}

.in-mobile-web-view table.score-list tr:nth-child(7) {
  border-bottom: 0;
}

/*# sourceMappingURL=index.8dfe1f8f.css.map */
